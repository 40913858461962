import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { callButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a href={`tel:${6045665092}`}>
              <CFView hover>
                <CFImage
                  src={callButton}
                  w="100%"
                  maxWidth="220px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a href={`tel:${6045665092}`}>
              <CFView hover>
                <CFImage
                  src={callButton}
                  w="100%"
                  maxWidth="170px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
