import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import CallButton from './CallButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyBetween
        >
          <Header />
          <CFView
            column
            justifyStart
            alignCenter
            bg="rgba(0,0,0,0.9)"
            pv="10px"
          >
            <CFView white xBold style={{ fontSize: 30 }}>
              NOW TAKING
            </CFView>
            <CFView white xBold style={{ fontSize: 30 }}>
              ONLINE ORDERS
            </CFView>
            <CFView pulsate mt="8px" textCenter>
              <OrderPickupButton />
            </CFView>
            <CFView mt="8px" textCenter>
              <CallButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="665px"
          w="100%"
          image={`url(${hero}) bottom / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,0.9)" ph="25px" pv="5px">
            <CFView xBold white style={{ fontSize: 28 }}>
              Welcome To Mazuya Sushi !
            </CFView>
            <CFView ml="20px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView ml="15px">
              <CallButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
